import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout";

const BlogSlugTemplate = ({ pageContext, data }) => {

  const translations = {
    en: {
      backButton: 'Back to Back Health Blog',
    },
    ch: {
      backButton: '返回護脊訊息'
    }
  }

  return (
    <Layout
      context={{
        ...pageContext,
        title: data.firestore.article.title,
        description: data.firestore.article.excerpt
      }}
      openGraph={{
        url: pageContext.permalink,
        title: data.firestore.article.title,
        description: data.firestore.article.excerpt,
        images: [
          {
            url: data.firestore.article.thumbnail
          }
        ]
      }}
    >
      <main className="my-12 flex justify-between w-full flex-col items-center">
        <img className="mb-6 max-h-[560px]" src={data.firestore.article.thumbnail} alt={data.firestore.article.title} />
        <span>{data.firestore.article.publishedAt}</span>
        <div className="prose-sm lg:prose mx-8" dangerouslySetInnerHTML={{ __html: data.firestore.article.bodyHTML }}>

        </div>
        <div className="mt-8 flex space-x-4">
          <a href={`/${pageContext.locale}/back-health-blog`} className="text-white bg-brick rounded-md px-6 py-3">{translations[pageContext.locale]['backButton']}</a>
        </div>
      </main>
    </Layout>
  );
}

export default BlogSlugTemplate;

export const query = graphql`
  query BlogSlugTemplateQuery($articleId: ID!) {
    firestore {
      article(id: $articleId) {
        id
        title
        thumbnail
        excerpt
        bodyHTML
        publishedAt
      }
    }
  }
`